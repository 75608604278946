import 'owl.carousel';

$(document).ready(() => {
    $(".slides").owlCarousel({
        nav: false,
        items: 1,
        loop: true,
        dots: true,
        autoplay: true,
        smartSpeed: 1400,
        addClassActive: true,
        animateOut: 'fadeOut',
        loop: $('.slides').children().length > 1,
        onTranslate: function (event) {
            $('.overlay-content').removeClass('aos-init').removeClass('aos-animate');
        },
        onTranslated: function (event) {
            $('.overlay-content').addClass('aos-init').addClass('aos-animate');
        }
    });
    $(".testimonial-slider").owlCarousel({
        nav: false,
        items: 1,
        loop: true,
        dots: false,
        autoplay: true,
        smartSpeed: 1400,
        addClassActive: true,
        animateOut: 'fadeOut',
        loop: $('.slides').children().length > 1,
    });
});