import './init-foundation.js';
import './owlcarousels.js';
import './aos.js';
import './magnific-popup.js';

$(document).ready(() => {
    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });
    $('.scrollup').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    // Scroll down button (Twice because of the header changing height)
    $('#scrolldown').click(function () {
        var scrolldownTarget = $('#scrolldown-target');
        $('html, body').animate({
            scrollTop: $(scrolldownTarget).offset().top * 0.9
        }, {
            duration: "300",
        });
    });

    // Add class to header once scrolled past X pixels
    function headerClass() {
        var scroll = $(window).scrollTop();
        if (scroll <= 0) {
            $("header").removeClass("scrolled");
        } else {
            $("header").addClass("scrolled");
        }
    }

    $(window).resize(function () {
        headerClass();
    });

    $(window).scroll(function () {
        headerClass();
    });

    headerClass();
});